
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message } from "ant-design-vue";
import iLetterLove from "@/components/input/LetterLove.vue";
import iLetterCharacter from "@/components/input/LetterCharacter.vue";
import iLetterMarriage from "@/components/input/LetterMarriage.vue";

import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";
import { TimeyyMMddHHmm,guid8 } from "@/store/guid";
interface GuestId {
  id: string; comment: string; longTime: number; success: number; 
}
export default defineComponent({
  name: "FooterHome",
  components: {

  },
  setup() {
        const data = reactive({
      id:TimeyyMMddHHmm() + guid8(),
      time: 0,
      userId:"",
      comment:"0",
      return:false,
    });
    
let guestId: any = JSON.parse(window.localStorage.getItem('GuestId')!);
    const GuestIdsUp = () => {
      let ip:string ="";
      // //console.log("1");
        if(guestId == null || guestId == "" || guestId == undefined || guestId == "null" || guestId == "undefined"){
          fetch('https://ipinfo.io/json')
        .then(res => res.json())
        .then(data => {
          ip = data.ip
          console.log(ip+"ip")
        })
        setTimeout(() => {
          request({
          url: "/AuthApi/LogComment",
          method: "POST",
          data: {
            ip: ip,
            ua:navigator.userAgent.toLocaleLowerCase(),
          }
        }).then((res: any) => {
          if (res.data.success) {
            console.log(res.data);
            data.comment=res.data.response
            // //console.log(res.data.response);
            if(data.comment=="0"||res.data.response=="0")
            {
              data.return=false;
              setTimeout(GuestIdsUp, 3000);
            }
            else if(data.comment.length>10||data.comment=="1"){
              const guestIds= reactive<GuestId>({
          id:data.id,
          comment:data.comment,
          longTime:0,
          success:0,
        });
        guestId=guestIds;
        window.localStorage.setItem("GuestId", JSON.stringify(guestIds));
        data.return=true;
            }
          }
        });
      }, 1500);
        
        }
        if(data.return==false&&data.comment!="1")
        {
          setTimeout(GuestIdsUp, 4000);
        }  
  }
  GuestIdsUp();

    const countLogPurUp = () => {
      let referrer = window.localStorage.getItem("referrer");
      // //console.log(data.time)
      if (data.return==true&&(data.time == 5 || data.time == 10 || data.time == 30 || data.time == 60 || data.time == 180 || data.time == 600 || data.time == 1800 || data.time == 3600)) {
        
        const uid = router.currentRoute.value;
        request({
          url: "/AuthApi/LogPur",
          method: "POST",
          data: {
            id: data.id,
            readurl: uid.path,
            refererurl: referrer  == null ?  document.referrer:referrer,
            guestid: guestId.id,
            readtime: data.time.toString(),
            success: guestId.success.toString(),
            types: uid.name,
            userid:data.userId==""?"":data.userId,
            comment:guestId.comment,
          }

        }).then((res: any) => {
          if (res.data.success) {
            guestId= JSON.parse(window.localStorage.getItem('GuestId')!);
            guestId.success++;
            window.localStorage.setItem("GuestId", JSON.stringify(guestId));
          }
        });
      }
      data.time++
      setTimeout(countLogPurUp, 1000);
    }
    countLogPurUp();
    return{

    }
  }
});
